/**
 * Test if we are on a dev environment.
 * @return {Boolean}
 */
export const isDev = () =>
  !['lignestbarth.com', 'www.lignestbarth.com'].includes(window.location.hostname);

export default {
  isDev,
};
