/* eslint-disable class-methods-use-this */
import gsap from 'gsap';
import Base from '@studiometa/js-toolkit';
import { isDev } from '../config';

/**
 * HeroSlider class.
 */
export default class HeroSlider extends Base {
  static config = {
    log: isDev(),
    name: 'HeroSlider',
    refs: ['slider', 'items[]', 'progress[]'],
  };

  /**
   * Mounted.
   */
  mounted() {
    if (!this.$refs.progress || this.$refs.items.length < 2) {
      return;
    }

    this.sliderIndex = 0;
    this.timelines = [];

    this.$refs.progress.forEach(($bar) => {
      const timeline = {
        progressing: null,
        finish: null,
      };

      // Progressing.
      timeline.progressing = gsap.timeline();
      timeline.progressing.pause();

      timeline.progressing.fromTo(
        $bar,
        {
          scaleX: 0,
        },
        {
          scaleX: 1,
          duration: 6,
          ease: 'linear',
          onComplete: this.handleTimelinesComplete.bind(this),
        }
      );

      // Finish.
      timeline.finish = gsap.timeline();
      timeline.finish.pause();

      timeline.finish.fromTo(
        $bar,
        {
          opacity: 1,
        },
        {
          opacity: 0,
          duration: 0.5,
          ease: 'linear',
          onComplete: this.handleTimelinesFinish.bind(this, this.timelines.length),
        }
      );

      this.timelines.push(timeline);
    });

    this.handleSliderReady();
  }

  /**
   * Handle slider ready.
   */
  handleSliderReady() {
    // this.$refs.slider.classList.add('is-flickity-ready');
    if (this.timelines.length) {
      this.timelines[0].progressing.play();
    }
  }

  /**
   * Handle slider change.
   */
  handleSliderChange() {
    this.timelines.forEach((timeline, i) => {
      if (this.sliderIndex !== i) {
        timeline.progressing.pause();
        timeline.finish.restart();
      }
    });

    this.timelines[this.sliderIndex].progressing.restart();
  }

  /**
   * Handle timeline progressing complete.
   */
  handleTimelinesComplete() {
    this.sliderNext();
  }

  /**
   * sliderNext
   */
  sliderNext() {
    const nextIndex = this.sliderIndex + 1 > this.$refs.items.length - 1 ? 0 : this.sliderIndex + 1;

    this.$refs.items.forEach(($item) => {
      $item.classList.remove('is-active');
    });

    this.$refs.items[nextIndex].classList.add('is-active');
    this.sliderIndex = nextIndex;
    this.handleSliderChange();
  }

  /**
   * Handle timeline progressing complete.
   */
  handleTimelinesFinish(index) {
    this.$refs.progress[index].style.opacity = '';
    this.$refs.progress[index].style.transform = '';
  }
}
