import gsap from 'gsap';
import Base from '@studiometa/js-toolkit';
import withBreakpointObserver from '@studiometa/js-toolkit/decorators/withBreakpointObserver';
import { isDev } from '../config';

/**
 * HeaderDesktop class.
 */
export default class HeaderDesktop extends withBreakpointObserver(Base) {
  static config = {
    log: isDev(),
    name: 'HeaderDesktop',
    options: {
      activeBreakpoints: {
        type: String,
        default: 'l xl xxl',
      },
    },
    refs: [
      'overlay',
      'topBar',
      'nav',
      'logo',
      'menuDesktop',
      'menuItems[]',
      'subMenu[]',
      'subMenuLinks[]',
      'subMenuImages[]',
    ],
  };

  /**
   * Mounted
   */
  mounted() {
    // Elements
    this.$html = document.documentElement;

    // Variables
    this.topBarHeight = this.$refs.topBar.clientHeight;
    this.headerHeight = this.$el.clientHeight;
    this.logoHeightMin = 69;
    this.logoHeightMax = 103;
    this.subMenuHeight = 0;

    this.tlEl = gsap.timeline();
    this.tlLogo = gsap.timeline();

    // Init
    this.$parent.setScrollDirection('top');

    this.tlEl.fromTo(
      this.$el,
      {
        y: 0,
      },
      {
        y: -this.topBarHeight,
        duration: 0.3,
        ease: 'power3.out',
      }
    );

    this.tlLogo.fromTo(
      this.$refs.logo,
      {
        height: 103,
      },
      {
        height: 34,
        duration: 0.3,
        ease: 'power3.inOut',
      }
    );

    this.tlEl.pause();
    this.tlLogo.pause();
  }

  /**
   * Scrolled
   */
  scrolled({ y }) {
    if (y <= 0) {
      // gsap.to(this.$refs.overlay, {
      //   height: 0,
      //   duration: 0.3,
      // });

      return;
    }

    if (this.$parent.isScrollBottom && this.tlEl.progress() !== 1) {
      this.tlEl.play();
      this.tlLogo.play();
    } else if (this.$parent.isScrollTop && this.tlEl.progress() !== 0) {
      this.tlEl.reverse();
      this.tlLogo.reverse();
    }
  }

  /**
   * Handle MenuItems mouseenter
   */
  onMenuItemsMouseenter() {
    this.$html.classList.add('is-submenu-enter');
  }

  /**
   * Handle MenuItems mouse leave
   */
  onMenuItemsMouseleave() {
    this.$html.classList.remove('is-submenu-enter');
  }

  /**
   * Handle SubMenuLinks mouse enter
   */
  onSubMenuLinksMouseenter(event) {
    const $el = event.target.matches('.menu-nav__link--secondary')
      ? event.target
      : event.target.closest('.menu-nav__link--secondary');
    const index = (($el || {}).dataset || {}).submenuLink || -1;

    if (index === -1) {
      return;
    }

    if (!this.$refs.subMenuImages.find(($image) => $image.dataset.submenuLink === index)) {
      return;
    }

    this.$refs.subMenuImages.forEach(($image) => {
      if ($image.dataset.submenuLink === index) {
        $image.classList.remove('opacity-0');
        $image.classList.add('opacity-100');
      } else {
        $image.classList.remove('opacity-100');
        $image.classList.add('opacity-0');
      }
    });
  }
}
