import Base from '@studiometa/js-toolkit';
import { isDev } from '../config';

/**
 * ContactForm class.
 */
export default class ContactForm extends Base {
  static config = {
    log: isDev(),
    name: 'ContactForm',
    formContactProId: 3,
    refs: ['titleContact', 'titlePro', 'formContact', 'formGroupPro[]'],
  };

  /**
   * Mounted
   */
  mounted() {
    this.handleProFormFields();
  }

  /**
   * Handle FormContact change
   */
  onFormContactChange(event) {
    this.handleProFormFields(event.target.value);
  }

  /**
   * onSubmit
   * @param {Event} event
   */
  onSubmit(event) {
    const recaptcha = document.getElementById('g-recaptcha-response').value;

    // recaptcha check
    if (recaptcha === '') {
      // display error
      const error = document.createElement('div');
      error.classList.add('notification', 'notification--error', 'wysiwyg', 'mt-6', 's:mt-12');
      error.innerHTML = 'Please fill the google recaptcha';
      this.$el.appendChild(error);

      event.preventDefault();
    }
  }

  /**
   * Handle pro form fields.
   * @param {int} formContactId
   */
  handleProFormFields(formContactId) {
    if (
      parseInt(formContactId ?? this.$refs.formContact.value, 10) === this.$options.formContactProId
    ) {
      this.showProFormFields();

      return;
    }
    this.hideProFormFields();
  }

  /**
   * showProFormFields
   * @description show the pro form fields when formContact === this.$options.formContactProId
   */
  showProFormFields() {
    this.$refs.titleContact.classList.add('hidden');
    this.$refs.formGroupPro.forEach(($formGroup) => $formGroup.classList.remove('hidden'));
    this.$refs.titlePro.classList.remove('hidden');
  }

  /**
   * hideProFormFields
   * @description hide the pro form fields when formContact !== this.$options.formContactProId
   */
  hideProFormFields() {
    this.$refs.formGroupPro.forEach(($formGroup) => $formGroup.classList.add('hidden'));
    this.$refs.titlePro.classList.add('hidden');
    this.$refs.titleContact.classList.remove('hidden');
  }
}
